import React from "react"
import {Routes, Route} from "react-router-dom"
import {urlAlert} from "api-methods"
import URedirect from "./uRedirect"
import Link from "./uLink"
import './App.css'


function App() {

    let pn = (document.location.pathname + "")
    if (pn.replace(/[?=&_A-Za-z0-9./:-]+/g, "") !== "") {
        if (pn !== '/instruction/expired_index%09') {
            urlAlert('char').then()
        }
    } else if ((pn.length > 8 && !!(pn.split('_')[0]).split('ru/')[1] && !!(pn.split('_')[0]).split('ru/')[1].length > 8) && pn.substr(0, 13) !== '/instruction/') {
        urlAlert('url').then()
    }

    return (
        <Routes>
            <Route path="/instruction/*" element={<URedirect />} />
            <Route path="/instruction/" element={<URedirect />} />
            <Route path="/*" element={<Link />} />
        </Routes>
    )
}

export default App
