import React, {useState} from "react"
import {urlGuidSearch, cSet, cGet, cDel, ajaxUpdate} from 'helper-func'
import {apiLinkGuid, apiLinkGuidCheckRt} from 'api-methods'

function App() {

    // 1. Params
    let guid            = urlGuidSearch(1).split('%20')[0]
    let cookiePin       = cGet('pin')
    let errorDefault    = 'неверный пин-код'
    let dirtyHack       = ['12845170','12845474']

    const [loader, setLoader]                   = useState(false)
    const [productData, setProductData]         = useState(false)
    const [pin, setPin]                         = useState('')
    const [pinErr, setPinErr]                   = useState(false)
    const [reqOne, setReqOne]                   = useState(false)
    const [errorMessage, setErrorMessage]       = useState(errorDefault)

    // 2. отправляем AJAX при вводе pin
    async function fetchData () {
        if (!reqOne) {
            try {
                //await req({method: apiUrl.sLink, body: {guid: guid, pin: cookiePin ? cookiePin : pin}}).then(data => {
                await apiLinkGuid(guid, cookiePin ? cookiePin : pin).then(data => {
                    if (data.code === 0) {
                        apiLinkGuidCheckRt(data)
                        setReqOne(true)
                        setPinErr(false)
                        cSet('pin', (cookiePin ? cookiePin : pin))

                        // Back-end splat ↓
                        if (dirtyHack.indexOf(data.data.productId + '') > -1) {
                            if (!!data.data.keyList && !!data.data.distrList) {
                                data.data.keyList.forEach((itemK, indexK) => {
                                    data.data.distrList.forEach((itemD) => {
                                        if (!data.data.keyList[indexK].di) {
                                            if ((itemD.key + '' !== 'ANDLINK' && data.data.keyList[indexK].name + '' !== 'betakey') || (itemD.key + '' === 'ANDLINK' && data.data.keyList[indexK].name + '' === 'betakey')) {
                                                data.data.keyList[indexK].di = itemD.key
                                                if (itemD.key === 'WINLINK32') {data.data.keyList[indexK].dn = 'Kaspersky WhoCalls'}
                                                else if (itemD.key === 'ANDLINK') {data.data.keyList[indexK].dn = 'Kaspersky Internet Security'}
                                            }
                                        }
                                    })
                                })
                                data.data.distrList.map((itemD, indexD) => {
                                    if (itemD.key === 'WINLINK32') {data.data.distrList[indexD].name = 'Kaspersky WhoCalls'}
                                    else if (itemD.key === 'ANDLINK') {data.data.distrList[indexD].name = 'Kaspersky Internet Security'}
                                })
                            }
                        }
                        // Back-end splat ↑

                        setProductData(data.data)
                    } else {
                        cDel('pin')
                        setErrorMessage(data.message)
                        setPinErr(true)
                        setReqOne(false)
                    }
                })
            } catch (err) {}
            setLoader(true)
        }
    }

    // 3. читаем куки
    if (cookiePin !== '') {fetchData().then()}

    // 4. Link req
    function apiCheck () {if (pin.toString().length === 4) {setPinErr(false);fetchData().then()} else {setErrorMessage(errorDefault);setPinErr(true)}}

    // 5. submit, input check
    function onSubmit (e) {e.preventDefault();apiCheck()}
    function inputPin (props) {setReqOne(false);setErrorMessage('');if (props + '' === '') {setPin('')} else if (parseInt(props)) {setPin(parseInt(props) + '')}}

    // 1.4. clear, resize, ajaxUpdate ↓
    if (document.location.hash === '#postmessage') {
        const [resizeValue, setResizeValue] = useState(800)
        function heightSend () {
            let actualPageSize = document.body.clientHeight + 40
            if (resizeValue !== actualPageSize) {
                setResizeValue(actualPageSize)
                window.parent.postMessage({height: actualPageSize}, '*')
            }
        }
        const [resizeEvent, setResizeEvent] = useState(false)
        if (!resizeEvent) {
            setResizeEvent(true)
            window.addEventListener('resize', function(event) {heightSend()}, true)
        }
        setTimeout(() => {heightSend()}, ajaxUpdate)
    }
    // 1.4. clear, resize, ajaxUpdate ↑

    return (
        !guid ? <div className="notFound">404</div> : ((!reqOne || !!pinErr)
            ? <div id="mainBlockPin">
                <div className="contentBlock">
                    <div className="partnerLogo" />
                    <div className="mainBlockPin__mainText">Пожалуйста, введите PIN, который пришел Вам в SMS-сообщении с адресом этой страницы (необходимо для безопасной передачи Вам кода активации антивируса).</div>
                    <form className="mainBlockPin__bottomWidth" onSubmit={e => onSubmit(e)}>
                        <input minLength="4" maxLength="4" placeholder="Введите PIN" value={pin} onChange={(e) => inputPin(e.target.value)}/>
                        {(!!pinErr && pin + '' !== '' && errorMessage + '' !== '') && <div className="mainBlockPin__bottomWidth-err">{'Ошибка: ' + errorMessage}</div>}
                        <div className="mainBlockPin__bottomWidth-key" onClick={() => apiCheck()}>Проверить</div>
                    </form>
                </div>
            </div>
            : !loader ? <div className="preLoader"><div className="loader" /></div> : <div id="mainBlock">
                <div className="partnerBlock"><div className="partnerLogo" /></div>
                <div className="contentBlock">
                    {productData.productName !== '' && <div className="youProduct">
                        {(productData.cover !== '' && productData.cover !== null) && (<div><img alt={productData.productName} className="productLogo" src={productData.cover} /></div>)}
                        <div>
                            <div className="youProduct__head-title">Вы успешно приобрели</div>
                            <div className="youProduct__head-name">{productData.productName}</div>
                            <div className="youProduct__head-button">{(!!productData.status && productData.status === 'active') ? <div className="subsActive">Подписка активна</div> : <div className="subsNoActive">Подписка не активна</div>}</div>
                        </div>
                    </div>}

                    {(productData.status === 'active') && ((!!productData.distrList)
                        ? <div className="instructions">
                            <div className="instructionsText">До полной защиты вашего устройства осталось несколько шагов:</div>
                            <div className="instructionsHead">1. Скачайте и установите программу на свое устройство</div>
                            <div className="instructionsLink">{productData.distrList.map((item, i) => {if (item.value !== '') {return <div key={i}><a target="_blank" href={item.value}>{item.name}</a></div>}return null;})}</div>
                            <div className="instructionsHead">2. Инструкция</div>
                            <div className="instructionsLink instructionsLink__instruction-border">При необходимости прочитайте <a className="" target="_blank" href={productData.instruction}>инструкцию</a>.</div>
                            {(Object.keys(productData.distrList).length > 0 && !!productData.keyList) && <div>
                                <div className="instructionsHead">3. Активация</div>
                                <div className="instructionsLink instructionsLink__instruction-border">
                                    Активация программы произойдет автоматически. Если этого не произошло, введите ключ активации:<br/><br/>
                                    {(dirtyHack.indexOf(productData.productId + '') > -1) ? (
                                        productData.keyList.map((item, iKeyList) => {
                                            return <div key={iKeyList}><div className="instructions__sn">{item.value}</div> - {item.dn}</div>
                                        })
                                    ) : (
                                        productData.keyList.map((item, iKeyList) => {
                                            return <div key={iKeyList}><div className="instructions__sn">{item.value}</div></div>
                                        })
                                    )}
                                </div>
                            </div>}
                        </div>
                        : <div className="instructions">
                            <div className="instructionsText">Дистрибутивы появятся в течении нескольких минут</div>
                        </div>
                    )}

                    {(productData.status === 'active' && productData.productName !== '' && (['349']).indexOf(productData.distributor_id + '') === -1) && <div className="freeProduct">
                        <div className="instructionsHelp__product">
                            <div>
                                <div className="instructionsHelp__product-head">
                                    <div>{productData.productName}</div>
                                    {(!productData.inTariff && productData.isTrial) && (<div className="instructionsHelp__product-title">Первые 30 дней в подарок. С 31 дня {productData.real_price} &#8381;/мес</div>)}
                                </div>
                                <div className="instructionsHelp__product-price">
                                    {(productData.inTariff) ? (<div className="instructionsHelp__price-rostelecom">В тарифе</div>) : (<div className="instructionsHelp__price-rostelecom">{productData.isTrial ? 0 : productData.real_price} <div className="priceLine">&#8381;/{(productData.duration >= 360) ? 'год' : 'мес'}</div></div>)}
                                </div>
                                <div className="instructionsHelp__product-clear" />
                            </div>
                        </div>
                        <div className="freeProduct__app addForm__buttons"><a target="_blank" href="https://lk.rt.ru">Подробнее</a></div>
                    </div>}

                    <div className="footer footer__padding"><div className="">Подробная информация о продукте, описание условий его использования, а также панель управления подпиской находятся в <a target="_blank" href="https://lk.rt.ru/">Едином Личном Кабинете</a> Ростелеком на странице услуги «Домашний интернет» в разделе «{((['349','367']).indexOf(productData.distributor_id + '') === -1) ? 'Антивирусы' : 'Цифровая помощь'}».<br /><br />С уважением, Ростелеком</div></div>
                </div>
            </div>)
    )
}

export default App
